import React from "react";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { purple } from "@mui/material/colors";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const theme = createTheme({
    typography: {
      fontSize: 13,
    },
    palette: {
      primary: {
        light: "#63b8ff",
        main: "#0989e3",
        dark: "#005db0",
        contrastText: "#000",
      },
      secondary: purple,
      info: {
        main: "#4db6ac",
        light: "#82e9de",
        dark: "#00867d",
        contrastText: "#fff",
      },
    },
    components: {
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            color: "#1565c0",
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#2196f3",
            border: "1px solid",
            backgroundColor: "#bbdefb",
          },
        },
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <GoogleOAuthProvider clientId="557975954123-r4i9lno2iejv1emlultjfadu8op7lfde.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box height="100vh" display="flex" flexDirection="column" style={{ backgroundColor: "#E7EBF0" }}>
            <Router>
              <Routes>
                {appRoutes.map((route) => (
                  <Route key={route.key} path={route.path} element={<route.component />} />
                ))}
              </Routes>
            </Router>
          </Box>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </LocalizationProvider>
  );
}

export default App;
