// src/pages/Home.tsx

import React, { ReactElement, FC, useState, useEffect, MouseEvent } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Typography,
  TextField,
  CircularProgress,
  Stack,
  Chip,
  Button,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
import { GamePricingDetails, PortablePlayerStats } from "./GamePricingDetails";
import { getPathContributingMatches } from "@remix-run/router/dist/utils";
import { MarketPricingDetails } from "./MarketPricingDetails";
import { Variant } from "@mui/material/styles/createTypography";
import {
  Announcement,
  BugReport,
  ConnectingAirportsOutlined,
  HowToReg,
  PersonSearchTwoTone,
  Report,
} from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import GoogleSignInButton, { useLoggedIn } from "../components/GoogleSignInButton";

interface Payload<T> {
  status: "init" | "loading" | "loaded" | "error";
  error?: Error;
  payload?: T;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface Fairs {
  outcome: string;
  line: number;
  raw: number;
  probAdj?: number;
  banditAndMkt?: number;
  banditMktAndMike?: number;
  blend?: number;
}

export interface LineTimingValue {
  available: boolean;
  currentTime: string;
  currentFairs: Fairs;
  timingValues: { [key: string]: Fairs };
}

interface SportId {
  id: string;
}

export interface TeamId {
  id: string;
}

export interface GameId {
  sport: SportId;
  date: Date;
  team1: TeamId;
  team2: TeamId;
}

export interface GameBetExecutionInfo {
  gameId: GameId;
  gameIdentifier: string;
  injuryStatus: number;
  marketExecutionStatus: { [key: string]: number };
}

const gameIdStr = (date: Moment, gameId: GameId, gameIdentifer: string) => {
  if (date >= moment("2024-01-24", "YYYY-MM-DD")) {
    return gameIdentifer;
  } else {
    const d = moment(gameId.date);

    return `${d.month()}/${d.day()}/${d.year()}_${gameId.team1.id}_${gameId.team2.id}`;
  }
};

const useGamePricingDetails = (loggedIn: boolean | undefined, date: Moment) => {
  const [result, setResult] = useState<Payload<GamePricingDetails[]>>({
    status: "init",
  });

  const dateArg = `${date.year()}-${date.month() + 1}-${date.date()}`;

  useEffect(() => {
    function getDetails() {
      fetch(`/api/GamePricingDetails?date=${dateArg}`)
        .then((response) => response.json())
        .then((response) => setResult({ status: "loaded", payload: response }))
        .catch((error) => {
          console.log(error.toString());
          setResult({ status: "error", error });
        });
    }
    if (loggedIn === true) {
      getDetails();
      const interval = setInterval(() => getDetails(), 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [dateArg, loggedIn]);

  return result;
};

const useGameBetExecutionInfo = (loggedIn: boolean | undefined, date: Moment) => {
  const [infos, setInfos] = useState<Payload<{ [key: string]: GameBetExecutionInfo }>>({
    status: "init",
  });

  const dateArg = `${date.year()}-${date.month() + 1}-${date.date()}`;

  useEffect(() => {
    function getDetails() {
      fetch(`/api/GameBetExecutionInfo?date=${dateArg}`)
        .then((response) => response.json())
        .then((response) => setInfos({ status: "loaded", payload: response }))
        .catch((error) => {
          console.log(error.toString());
          setInfos({ status: "error", error });
        });
    }
    if (loggedIn === true) {
      getDetails();
      const interval = setInterval(() => getDetails(), 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [dateArg, loggedIn]);

  return { infos, setInfos };
};

const displayWithSign = (
  value: number | null | undefined,
  oppositeSign: boolean = false,
  roundedDigits: number = 0
) => {
  if (value === undefined || value === null) {
    return "";
  }

  if (oppositeSign) {
    value = value * -1;
  }

  if (value < 0) {
    return value?.toFixed(roundedDigits);
  }

  return `+${value?.toFixed(roundedDigits)}`;
};

const probToOdds = (probability: number | undefined | null) => {
  if (probability === undefined || probability === null) {
    return undefined;
  }

  return probability >= 0.5 ? -100 * (probability / (1 - probability)) : ((1 - probability) / probability) * 100;
};

const getAttributes = (
  gameDetails: GamePricingDetails,
  details: MarketPricingDetails | undefined,
  marketExecutionStatus: number | undefined,
  onStatusUpdated: (event: MouseEvent<HTMLButtonElement>) => void
) => {
  const firstRow = [];
  const secondRow = [];

  let status = "FILLING";
  let color: "primary" | "success" | "warning" | "secondary" | "info" = "primary";
  if (marketExecutionStatus === 1) {
    status = "FULL";
    color = "success";
  } else if (marketExecutionStatus === 2) {
    status = "IGNORE";
    color = "warning";
  } else if (marketExecutionStatus === 3) {
    status = "HOLDING";
    color = "secondary";
  }

  firstRow.push(
    <Button
      key="status"
      variant="contained"
      size="small"
      color={color}
      sx={{ color: "white" }}
      onClick={onStatusUpdated}
    >
      STATUS: {status}
    </Button>
  );

  const mikeOpinion = details?.projections["Mike"];
  const dataVariant = gameDetails.dataValidity < 0.96 ? "contained" : "outlined";
  const dataColor =
    gameDetails.dataValidity >= 0.965 ? "primary" : gameDetails.dataValidity > 0.96 ? "warning" : "error";
  firstRow.push(
    <Button key="dvp" variant={dataVariant} size="small" color={dataColor}>
      PBP: {(gameDetails.dataValidity * 100).toFixed(1)}%
    </Button>
  );

  if (details !== undefined && details.attributes["PaceBiasL1"] !== undefined) {
    firstRow.push(
      <Button key="pace" variant="outlined" size="small">
        PACE: {details.attributes["PaceBiasL1"]}, {details.attributes["OppPaceBiasL1"]},{" "}
        {details.attributes["PaceBiasL5"]}, {details.attributes["OppPaceBiasL5"]}
      </Button>
    );
  }
  if (mikeOpinion !== undefined && mikeOpinion !== null) {
    firstRow.push(
      <Button key="mike" variant="contained" size="small" color="info">
        MIKE: {mikeOpinion}
      </Button>
    );
  }

  const simMean = details?.projections["SimMean"]!! ? Number(details.projections["SimMean"]) : null;
  const simAdjMean = details?.projections["SimAdjMean"]!! ? Number(details.projections["SimAdjMean"]) : null;
  //const daniMean = details?.projections["DaniMean"];
  //const erikMean = details?.projections["ErikMean"];
  if (simMean !== undefined && simMean !== null) {
    secondRow.push(
      <Button key="raw" variant="outlined" size="small">
        RAW: {displayWithSign(simMean, false, 1)}
      </Button>
    );
  }
  if (simAdjMean !== undefined && simAdjMean !== null) {
    secondRow.push(
      <Button key="bias" variant="outlined" size="small">
        ADJ: {displayWithSign(simAdjMean, false, 1)}
      </Button>
    );
  }
  //if (erikMean !== undefined && erikMean !== null) {
  //  secondRow.push(
  //    <Button key="erik" variant="outlined" size="small">
  //      ERIK: {displayWithSign(erikMean, false, 1)}
  //    </Button>
  //  );
  //}
  if (
    details?.marketAdjustedMeans !== undefined &&
    details?.marketAdjustedMeans !== null &&
    details.lineTimingValues.length > 0
  ) {
    secondRow.push(
      <Tooltip title="Bandit Adj + Market">
        <Button key="market" variant="outlined" size="small">
          W/ MKT:{" "}
          {displayWithSign(
            details?.marketAdjustedMeans[details.lineTimingValues[0].currentTime].banditAndMkt,
            false,
            1
          )}
        </Button>
      </Tooltip>
    );

    if (mikeOpinion !== undefined && mikeOpinion !== null) {
      secondRow.push(
        <Tooltip title="Bandit Adj + Market + Mike">
          <Button key="marketmike" variant="outlined" size="small">
            W/ M&M:{" "}
            {displayWithSign(
              details?.marketAdjustedMeans[details.lineTimingValues[0].currentTime].banditMktAndMike,
              false,
              1
            )}
          </Button>
        </Tooltip>
      );
    }
  }

  if (
    details !== undefined &&
    details.attributes["MissingSims"] !== undefined &&
    (details.attributes["MissingSims"] !== "0" || details.attributes["OppMissingSims"] !== "0")
  ) {
    firstRow.push(
      <Button key="sims" variant="contained" size="small" color="warning">
        SIMS: {details.attributes["MissingSims"]}, {details.attributes["OppMissingSims"]}
      </Button>
    );
  }

  return (
    <Stack direction="column">
      <Stack direction="row" spacing={1} sx={{ paddingTop: ".5em", paddingBottom: ".5em" }}>
        {firstRow}
      </Stack>
      <Stack direction="row" spacing={1} sx={{ paddingTop: ".5em", paddingBottom: ".5em" }}>
        {secondRow}
      </Stack>
    </Stack>
  );
};

const fairRows = (
  fairs: Fairs | undefined,
  isMedian: boolean,
  fontWeight: string,
  variant: Variant,
  isSpread: boolean
) => {
  if (fairs === undefined) {
    return undefined;
  }

  let coverPct = fairs.blend;
  if (isMedian && (coverPct === undefined || coverPct === null)) {
    coverPct = fairs.blend || fairs.banditMktAndMike || fairs.banditAndMkt || fairs.probAdj || fairs.raw;
  }
  const blendOdds = probToOdds(coverPct);
  const probAdj = probToOdds(fairs.probAdj)?.toFixed(1);
  const banditAndMkt = probToOdds(fairs.banditAndMkt)?.toFixed(1);
  const banditMktAndMike = probToOdds(fairs.banditMktAndMike)?.toFixed(1);
  const raw = probToOdds(fairs.raw)?.toFixed(1);
  const color = (blendOdds ?? 0) <= -118 ? "info.main" : "text.primary";
  const blendOddsStr = blendOdds?.toFixed(1);

  if (coverPct !== undefined) {
    return (
      <React.Fragment>
        <TableCell align="center">
          <Typography sx={{ fontWeight: fontWeight }} color={color} variant={variant}>
            {displayWithSign(!!blendOddsStr ? +blendOddsStr : undefined, false, 1)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontWeight: fontWeight }} color={color} variant={variant}>
            {displayWithSign(!!banditAndMkt ? +banditAndMkt : undefined, false, 1)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontWeight: fontWeight }} color={color} variant={variant}>
            {displayWithSign(!!probAdj ? +probAdj : undefined, false, 1)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontWeight: fontWeight }} color={color} variant={variant}>
            {displayWithSign(!!raw ? +raw : undefined, false, 1)}
          </Typography>
        </TableCell>
      </React.Fragment>
    );
  }

  return undefined;
};

interface RowProps {
  date: Moment;
  details: GamePricingDetails;
  betExecInfo: GameBetExecutionInfo | undefined;
  betExecInfoUpdated: (gameIdentifer: string, info: GameBetExecutionInfo) => void;
  markets: string[];
  aggressive: boolean;
}

function Row(props: RowProps) {
  const { details, betExecInfo, markets, aggressive } = props;
  const [open, setOpen] = React.useState(false);
  const [context, setContext] = React.useState(0);

  const totalsMarket = details.markets.find((m) => m.marketName === "Total");
  const h1TotalsMarket = details.markets.find((m) => m.marketName === "1H Total");
  const spreadMarket = details.markets.find((m) => m.marketName === "Spread");
  const moneylineMarket = details.markets.find((m) => m.marketName === "Moneyline");

  const marketToDisplay =
    context === 0 ? totalsMarket : context === 1 ? h1TotalsMarket : context === 2 ? spreadMarket : null;
  const isSpread = marketToDisplay === spreadMarket;

  let spreadProjectionStr =
    //spreadMarket?.projections["DaniAdjMean"] ??
    //spreadMarket?.projections["ErikAdjMean"] ??
    spreadMarket?.projections["SimAdjMean"] ?? spreadMarket?.projections["SimMean"] ?? spreadMarket?.simMedian;

  let spreadProjection = spreadProjectionStr!! ? Number(spreadProjectionStr) : undefined;

  const totalProjectionStr =
    //totalsMarket?.projections["DaniAdjMean"] ??
    //totalsMarket?.projections["ErikAdjMean"] ??
    totalsMarket?.projections["SimAdjMean"] ?? totalsMarket?.projections["SimMean"] ?? totalsMarket?.simMedian;

  const totalProjection = totalProjectionStr!! ? Number(totalProjectionStr) : undefined;

  const reviewInjuries = details.team1InjuryAreas.length > 0 || details.team2InjuryAreas.length > 0;

  let injuryStateIcon = reviewInjuries ? <PersonSearchTwoTone /> : <HowToReg color="primary" />;
  let disabled = false;
  if (betExecInfo?.injuryStatus === 1) {
    injuryStateIcon = <HowToReg color="primary" />;
  } else if (betExecInfo?.injuryStatus === 2) {
    injuryStateIcon = <Announcement color="warning" />;
  } else if (betExecInfo?.injuryStatus === 3) {
    injuryStateIcon = <Report color="error" />;
    disabled = true;
  }

  const toggleInjuryState = (event: MouseEvent<HTMLButtonElement>) => {
    if (!betExecInfo) {
      return;
    }
    event.stopPropagation();

    const copy = { ...betExecInfo };
    copy.injuryStatus = (betExecInfo.injuryStatus + 1) % 4;

    props.betExecInfoUpdated(details.gameIdentifier, copy);
  };

  const toggleMarketStatus = (event: MouseEvent<HTMLButtonElement>, marketName: string | undefined) => {
    if (!betExecInfo || !marketName) {
      return;
    }
    const marketStatus = betExecInfo?.marketExecutionStatus[marketName] ?? 0;
    event.stopPropagation();

    const copy = { ...betExecInfo };
    copy.marketExecutionStatus = { ...betExecInfo.marketExecutionStatus };
    copy.marketExecutionStatus[marketName] = (marketStatus + 1) % 4;

    props.betExecInfoUpdated(details.gameIdentifier, copy);
  };

  const gameIdToClipboard = (event: MouseEvent<HTMLTableCellElement>, gameId: string) => {
    navigator.clipboard.writeText(gameId);
    event.stopPropagation();
  };

  const rotationAndNameToClipboard = (event: MouseEvent<HTMLSpanElement>, text: string) => {
    navigator.clipboard.writeText(text);
    event.stopPropagation();
  };

  const getMarketChip = (
    reviewInjuries: boolean,
    shouldBet: boolean | undefined,
    bestNumber: string | undefined,
    marketExecutionStatus: number | undefined
  ) => {
    const isHolding = marketExecutionStatus === 3;
    const isIgnored = marketExecutionStatus === 2;
    const isFull = marketExecutionStatus === 1;
    let sx = {};
    if (
      (betExecInfo === undefined || betExecInfo?.injuryStatus === 0) &&
      reviewInjuries &&
      shouldBet &&
      !disabled &&
      !isFull &&
      !isIgnored
    ) {
      sx = { backgroundColor: !isHolding ? "#2e7d3280" : "#7b1fa2" };
    }

    return (
      <Chip
        sx={sx}
        variant={shouldBet && !disabled && !isFull && !isIgnored ? "filled" : "outlined"}
        color={
          shouldBet && !disabled && !isIgnored
            ? !isHolding
              ? "success"
              : "secondary"
            : shouldBet && isIgnored
            ? "warning"
            : "default"
        }
        size="small"
        label={bestNumber}
      />
    );
  };

  let firstRotNum = details.awayOrTeam1RotNum;
  let firstTeam = details.awayOrTeam1DisplayName;
  let secondRotNum = details.homeOrTeam2RotNum;
  let secondTeam = details.homeOrTeam2DisplayName;

  let firstSpreadMarketChip = getMarketChip(
    reviewInjuries,
    aggressive ? spreadMarket?.betBestAvailableMin || spreadMarket?.betMin : spreadMarket?.betMin,
    displayWithSign(spreadMarket?.bestAvailableMin, false, 1),
    betExecInfo?.marketExecutionStatus[spreadMarket?.marketName ?? ""]
  );
  let secondSpreadMarketChip = getMarketChip(
    reviewInjuries,
    aggressive ? spreadMarket?.betBestAvailableMax || spreadMarket?.betMax : spreadMarket?.betMax,
    displayWithSign(spreadMarket?.bestAvailableMax, true, 1),
    betExecInfo?.marketExecutionStatus[spreadMarket?.marketName ?? ""]
  );

  let teamsSwapped = false;
  if (details.isNeutral && firstRotNum != null && secondRotNum != null && firstRotNum > secondRotNum) {
    const tempRotNum = firstRotNum;
    const tempTeam = firstTeam;
    const tempSpreadChip = firstSpreadMarketChip;

    firstRotNum = secondRotNum;
    firstTeam = secondTeam;
    firstSpreadMarketChip = secondSpreadMarketChip;

    secondRotNum = tempRotNum;
    secondTeam = tempTeam;
    secondSpreadMarketChip = tempSpreadChip;

    spreadProjection = spreadProjection === undefined ? undefined : spreadProjection * -1;

    teamsSwapped = true;
  }

  const asPercent = (num: number, precision: number = 2): string => {
    let formatted = num.toFixed(precision);
    formatted = formatted.replace(/^0\./, "").replace(/\.0+$/, "");
    return `${formatted}%`;
  };

  const getHeaderCell = (name: string, toReview: string[]) => {
    const needsReview = toReview.indexOf(name) >= 0;

    const fontWeight = needsReview ? "bold" : "normal";
    const color = needsReview ? "red" : "black";
    return <TableCell sx={{ fontWeight: fontWeight, color: color }}>{name}</TableCell>;
  };

  const getPlayersTable = (team: string, injuryAreas: string[], players: PortablePlayerStats[]) => {
    return (
      <Box sx={{ paddingTop: ".75em" }}>
        <Typography align="center" variant="subtitle1">
          {team}
        </Typography>
        <Table size="small" sx={{ "& .MuiTableCell-root": { fontSize: 10, whiteSpace: "nowrap" } }}>
          <TableHead>
            <TableRow>
              <TableCell>PLAYER</TableCell>
              <TableCell>S / G</TableCell>
              {getHeaderCell("MIN", injuryAreas)}
              {getHeaderCell("PTS", injuryAreas)}
              {getHeaderCell("2A", injuryAreas)}
              {getHeaderCell("3A", injuryAreas)}
              {getHeaderCell("FTA", injuryAreas)}
              {getHeaderCell("OR", injuryAreas)}
              {getHeaderCell("DR", injuryAreas)}
              {getHeaderCell("A", injuryAreas)}
              {getHeaderCell("B", injuryAreas)}
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map((data, index) => {
              const isOut = data.likelyUnavailable;

              const fontWeight = isOut ? "bold" : "normal";
              const color = isOut ? "red" : "black";
              return (
                <TableRow
                  key={data.playerName}
                  sx={{ "& .MuiTableCell-root": { fontWeight: fontWeight, color: color } }}
                >
                  <TableCell>
                    {data.playerName} ({data.positionShort})
                  </TableCell>
                  <TableCell>
                    {data.starts} / {data.gamesPlayed}
                  </TableCell>
                  <TableCell>{asPercent(data.pctOfTeamMinutes)}</TableCell>
                  <TableCell>{asPercent(data.pctOfTeamPoints)}</TableCell>
                  <TableCell>{asPercent(data.pctOfTeamTwoPointAtt)}</TableCell>
                  <TableCell>{asPercent(data.pctOfTeamThreePointAtt)}</TableCell>
                  <TableCell>{asPercent(data.pctOfTeamFreeThrowAtt)}</TableCell>
                  <TableCell>{asPercent(data.pctOfTeamOffReb)}</TableCell>
                  <TableCell>{asPercent(data.pctOfTeamDefReb)}</TableCell>
                  <TableCell>{asPercent(data.pctOfTeamAssists)}</TableCell>
                  <TableCell>{asPercent(data.pctOfTeamBlocks)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell width="1%">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" width="2%" onClick={(e) => gameIdToClipboard(e, details.gameIdentifier)}>
          {new Date(details.date).toLocaleTimeString().replace(":00 ", " ")}
        </TableCell>
        <TableCell component="th" scope="row" width="2%" align="right">
          <Stack spacing={0.5} direction="column">
            <span onClick={(e) => rotationAndNameToClipboard(e, `${firstRotNum} ${firstTeam}`)}>{firstRotNum}</span>
            <span onClick={(e) => rotationAndNameToClipboard(e, `${secondRotNum} ${secondTeam}`)}>{secondRotNum}</span>
          </Stack>
        </TableCell>
        <TableCell component="th" scope="row" width="10%">
          <Stack spacing={0.5} direction="column">
            <span>{firstTeam}</span>
            <span>{secondTeam}</span>
          </Stack>
        </TableCell>
        <TableCell width="2%">
          {details.biasDataMissing ? (
            <Tooltip title="Bias data missing">
              <BugReport color="secondary" />
            </Tooltip>
          ) : undefined}
          {details.isNeutral ? (
            <Tooltip title="Neutral site">
              <ConnectingAirportsOutlined />
            </Tooltip>
          ) : undefined}
        </TableCell>
        <TableCell width="2%">
          <IconButton size="large" onClick={toggleInjuryState}>
            {injuryStateIcon}
          </IconButton>
        </TableCell>

        {markets.indexOf(FGTotal) === -1 ? undefined : (
          <TableCell align="center" width="10%">
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <h4>{totalProjection?.toFixed(1)}</h4>
              <Stack spacing={0.5} direction="column">
                {getMarketChip(
                  reviewInjuries,
                  aggressive ? totalsMarket?.betBestAvailableMin || totalsMarket?.betMin : totalsMarket?.betMin,
                  totalsMarket?.bestAvailableMin?.toFixed(1),
                  betExecInfo?.marketExecutionStatus[totalsMarket?.marketName ?? ""]
                )}
                {getMarketChip(
                  reviewInjuries,
                  aggressive ? totalsMarket?.betBestAvailableMax || totalsMarket?.betMax : totalsMarket?.betMax,
                  totalsMarket?.bestAvailableMax?.toFixed(1),
                  betExecInfo?.marketExecutionStatus[totalsMarket?.marketName ?? ""]
                )}
              </Stack>
            </Stack>
          </TableCell>
        )}

        {markets.indexOf(H1Total) === -1 ? undefined : (
          <TableCell align="center" width="10%">
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <h4>{h1TotalsMarket?.simMedian}</h4>
              <Stack spacing={0.5} direction="column">
                {getMarketChip(
                  reviewInjuries,
                  aggressive ? h1TotalsMarket?.betBestAvailableMin || h1TotalsMarket?.betMin : h1TotalsMarket?.betMin,
                  h1TotalsMarket?.bestAvailableMin?.toString(),
                  betExecInfo?.marketExecutionStatus[h1TotalsMarket?.marketName ?? ""]
                )}
                {getMarketChip(
                  reviewInjuries,
                  aggressive ? h1TotalsMarket?.betBestAvailableMax || h1TotalsMarket?.betMax : h1TotalsMarket?.betMax,
                  h1TotalsMarket?.bestAvailableMax?.toString(),
                  betExecInfo?.marketExecutionStatus[h1TotalsMarket?.marketName ?? ""]
                )}
              </Stack>
            </Stack>
          </TableCell>
        )}

        {markets.indexOf(FGSpread) === -1 ? undefined : (
          <TableCell align="center" width="10%">
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <h4>{`${firstTeam} ${displayWithSign(spreadProjection, false, 1)}`}</h4>
              <Stack spacing={0.5} direction="column">
                {firstSpreadMarketChip}
                {secondSpreadMarketChip}
              </Stack>
            </Stack>
          </TableCell>
        )}

        {markets.indexOf(FGMoneyline) === -1 ? undefined : (
          <TableCell align="center" width="10%">
            {details.isNeutral ? undefined : (
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                <h4>{`${probToOdds(moneylineMarket?.simMedian)?.toFixed(0) ?? ""}`}</h4>
                <Stack spacing={0.5} direction="column">
                  {getMarketChip(
                    reviewInjuries,
                    moneylineMarket?.betMin,
                    displayWithSign(moneylineMarket?.bestAvailableMin),
                    betExecInfo?.marketExecutionStatus[moneylineMarket?.marketName ?? ""]
                  )}
                  {getMarketChip(
                    reviewInjuries,
                    moneylineMarket?.betMax,
                    displayWithSign(moneylineMarket?.bestAvailableMax),
                    betExecInfo?.marketExecutionStatus[moneylineMarket?.marketName ?? ""]
                  )}
                </Stack>
              </Stack>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Tabs
                  variant="scrollable"
                  value={context}
                  onChange={(e, data) => setContext(data)}
                  aria-label="Vertical tabs example"
                >
                  <Tab label="TOTAL" />
                  <Tab label="1H TOTAL" />
                  <Tab label="Spread" />
                  <Tab label="PLAYERS" />
                </Tabs>
                <TabPanel value={0} index={0}>
                  {marketToDisplay !== null ? (
                    <React.Fragment>
                      <div key={marketToDisplay?.marketName}>
                        {getAttributes(
                          details,
                          marketToDisplay,
                          betExecInfo?.marketExecutionStatus[marketToDisplay?.marketName ?? ""],
                          (event: MouseEvent<HTMLButtonElement>) =>
                            toggleMarketStatus(event, marketToDisplay?.marketName)
                        )}
                      </div>

                      {marketToDisplay === moneylineMarket ? undefined : (
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>BET</TableCell>
                              <TableCell>LINE</TableCell>
                              <Tooltip title="Bandit Adj + Market + Mike (if he has an opinion)">
                                <TableCell>BLEND</TableCell>
                              </Tooltip>
                              <Tooltip title="Bandit Adj + Market (No Mike)">
                                <TableCell>W/ MKT</TableCell>
                              </Tooltip>
                              <Tooltip title="Bandit distribution with bias adjustments">
                                <TableCell>DIST</TableCell>
                              </Tooltip>
                              <Tooltip title="Bandit raw sim">
                                <TableCell>RAW</TableCell>
                              </Tooltip>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {marketToDisplay?.lineTimingValues.map((data, index) => {
                              const isMedian =
                                (index === 6 || index === 5) &&
                                Math.abs(data.currentFairs.line) === Math.abs(marketToDisplay.marketMedian ?? 100);

                              const fontWeight = isMedian ? "bold" : "normal";
                              const variant: Variant = "body2";
                              return (
                                <TableRow key={data.currentFairs.line}>
                                  <TableCell component="th" scope="row">
                                    <Typography sx={{ fontWeight: fontWeight }} variant={variant}>
                                      {data.currentFairs.outcome}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ fontWeight: fontWeight }} variant={variant}>
                                      {data.currentFairs.line}
                                    </Typography>
                                  </TableCell>
                                  {fairRows(data.currentFairs, isMedian, fontWeight, variant, isSpread)}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      )}
                    </React.Fragment>
                  ) : (
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                      {getPlayersTable(
                        details.awayOrTeam1DisplayName,
                        details.team1InjuryAreas,
                        details.awayOrTeam1PlayerStats
                      )}
                      {getPlayersTable(
                        details.homeOrTeam2DisplayName,
                        details.team2InjuryAreas,
                        details.homeOrTeam2PlayerStats
                      )}
                    </Stack>
                  )}
                </TabPanel>
              </Box>
              <iframe
                title={props.details.gameIdentifier}
                width={400}
                height={560}
                src={
                  "https://etherpad.wikimedia.org/p/" +
                  gameIdStr(props.date, props.details.gameId, props.details.gameIdentifier)
                    .replaceAll("/", "")
                    .replaceAll("_", "") +
                  "?showChat=false"
                }
              ></iframe>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const FGTotal = "Total";
const H1Total = "1H Total";
const FGSpread = "Spread";
const FGMoneyline = "Moneyline";

const allMarkets = [FGTotal, H1Total, FGSpread, FGMoneyline];
const initialMarkets = [FGTotal, FGSpread];

const MainGames = "Mains";
const ExtraGames = "Extras";
const allGameTypes = [MainGames, ExtraGames];

export default function CollapsibleTable() {
  const [date, setDate] = useState<Moment>(moment());
  const [markets, setMarkets] = useState<string[]>(initialMarkets);
  const [gameTypes, setGameTypes] = useState<string[]>(allGameTypes);
  const [aggressive, setAggressive] = useState<boolean>(false);

  const { loggedIn, setLoggedIn, logout } = useLoggedIn();
  const service = useGamePricingDetails(loggedIn, date);
  service?.payload?.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1));

  const { infos, setInfos } = useGameBetExecutionInfo(loggedIn, date);

  const getInfoForGame = (gameIdentifier: string) => {
    if (!!infos?.payload) {
      return infos.payload[gameIdentifier];
    }

    return undefined;
  };

  const handleAggressionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAggressive(event.target.checked);
  };

  const handleMarketsChange = (event: SelectChangeEvent<typeof markets>) => {
    const {
      target: { value },
    } = event;
    setMarkets(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleGameTypesChange = (event: SelectChangeEvent<typeof markets>) => {
    const {
      target: { value },
    } = event;
    setGameTypes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const betExecInfoUpdated = (gameIdentifer: string, info: GameBetExecutionInfo) => {
    const copy = { ...infos };
    if (!copy.payload) {
      return;
    }

    copy.payload[gameIdentifer] = info;

    setInfos(copy);

    fetch(`/api/GameBetExecutionInfo`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(info),
    }).catch((error) => {
      console.log(error.toString());
      setInfos({ status: "error", error });
    });
  };

  const showMains = gameTypes.indexOf(MainGames) > -1;
  const showExtras = gameTypes.indexOf(ExtraGames) > -1;

  const logoutButton =
    loggedIn === true ? (
      <Box sx={{ display: "flex", justifyContent: "center", paddingBottom: "1em", paddingTop: "1em" }}>
        <Button key="status" variant="contained" size="small" color="primary" sx={{ color: "white" }} onClick={logout}>
          Logout
        </Button>
      </Box>
    ) : null;

  const table =
    service.payload === undefined || loggedIn !== true ? (
      loggedIn === true ? (
        <Container maxWidth="sm" style={{ padding: "1em" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </Container>
      ) : (
        <Container maxWidth="sm" style={{ padding: "1em" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <GoogleSignInButton setLoggedIn={setLoggedIn} />
          </Box>
        </Container>
      )
    ) : (
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Time</TableCell>
              <TableCell align="right">Rotation</TableCell>
              <TableCell>Teams</TableCell>
              <TableCell></TableCell>
              <TableCell>Injuries</TableCell>
              {markets.indexOf(FGTotal) > -1 ? <TableCell align="center">Game Total</TableCell> : undefined}
              {markets.indexOf(H1Total) > -1 ? <TableCell align="center">1H Total</TableCell> : undefined}
              {markets.indexOf(FGSpread) > -1 ? <TableCell align="center">Game Spread</TableCell> : undefined}
              {markets.indexOf(FGMoneyline) > -1 ? <TableCell align="center">Moneyline</TableCell> : undefined}
            </TableRow>
          </TableHead>
          <TableBody>
            {service.payload.map((row) =>
              row.awayOrTeam1RotNum === null ||
              (showExtras && row.awayOrTeam1RotNum > 10000) ||
              (showMains && row.awayOrTeam1RotNum < 10000) ? (
                <Row
                  key={row.descriptionWithDate}
                  date={date}
                  details={row}
                  betExecInfo={getInfoForGame(row.gameIdentifier)}
                  betExecInfoUpdated={betExecInfoUpdated}
                  markets={markets}
                  aggressive={aggressive}
                />
              ) : undefined
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return (
    <div style={{ padding: "1em", backgroundColor: "#E7EBF0" }}>
      <Box sx={{ display: "flex", justifyContent: "right", paddingBottom: "1em" }}>
        <Stack direction="row" spacing={1.5}>
          <DatePicker
            sx={{ width: 150 }}
            label="Date"
            openTo="day"
            views={["year", "month", "day"]}
            value={date}
            onChange={(newValue) => {
              setDate(newValue || moment());
            }}
          />
          <div>
            <FormControl sx={{ width: 150 }}>
              <InputLabel id="game-types-label">Game Types</InputLabel>
              <Select
                labelId="game-types-label"
                id="game-types"
                multiple
                value={gameTypes}
                onChange={handleGameTypesChange}
                input={<OutlinedInput label="Game Types" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {allGameTypes.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={gameTypes.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ width: 225 }}>
              <InputLabel id="markets-label">Markets</InputLabel>
              <Select
                labelId="markets-label"
                id="markets"
                multiple
                value={markets}
                onChange={handleMarketsChange}
                input={<OutlinedInput label="Markets" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {allMarkets.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={markets.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Box alignItems="center" flexDirection="column" sx={{ display: "flex", justifyContent: "center" }}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={aggressive} onChange={handleAggressionChange} />}
                label="Aggressive"
              />
            </FormGroup>
          </Box>
        </Stack>
      </Box>

      {table}
      {logoutButton}
    </div>
  );
}
