// src/routes.ts

// pages
import Home from "./pages/CBB";

// other
import {FC} from "react";

// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<{}>
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        title: 'CBB',
        path: '/',
        enabled: true,
        component: Home
    },
    /*{
        key: 'about-route',
        title: 'About',
        path: '/about',
        enabled: true,
        component: About
    },*/
]