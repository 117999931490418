import React, { useEffect, useState } from "react";
import { GoogleLogin, googleLogout } from "@react-oauth/google";

export interface LoggedInResponse {
  loggedIn: boolean;
}

export const useLoggedIn = () => {
  const [loggedIn, setLoggedIn] = useState<LoggedInResponse | undefined>(undefined);

  const logout = () => {
    googleLogout();

    fetch("/api/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setLoggedIn({ loggedIn: false });
        }
      })
      .catch((error) => {
        console.log(error.toString());
      });
  };

  useEffect(() => {
    function getLoggedIn() {
      fetch(`/api/auth`)
        .then((response) => response.json())
        .then((response) => setLoggedIn(response))
        .catch((error) => {
          console.log(error.toString());
        });
    }
    if (loggedIn === undefined) {
      getLoggedIn();
    }
  }, [loggedIn]);

  return { loggedIn: loggedIn?.loggedIn, setLoggedIn, logout };
};

interface GoogleSingInButtonProps {
  setLoggedIn: (value: LoggedInResponse) => void;
}

function GoogleSignInButton(props: GoogleSingInButtonProps) {
  const handleSuccess = (credentialResponse: any) => {
    // Send the credential response (id_token) to your backend for verification
    fetch("/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ IdToken: credentialResponse.credential }),
    })
      .then((response) => {
        if (response.ok) {
          props.setLoggedIn({ loggedIn: true });
        } else {
          alert("I don't think so, budddaaayyyyy");
        }
      })
      .catch((error) => {
        console.log(error.toString());
      });
  };

  const handleFailure = () => {
    console.error("Google Sign-In failed:");
  };

  return <GoogleLogin onSuccess={handleSuccess} onError={handleFailure} />;
}

export default GoogleSignInButton;
